import { router } from "@inertiajs/react";
import { Driver } from "driver.js";
import {
	createContext,
	FC,
	ReactNode,
	RefObject,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState
} from "react";

import { SizeContext } from "@/ContextProviders/SizeProvider";

export type DriverTourContextProps = {
	startMainTour: () => void;
	startSearchMiniTour: () => void;
	mainTour: RefObject<Driver | null>;
	searchMiniTour: RefObject<Driver | null>;
	showTabs: boolean;
};

export const DriverTourContext = createContext<DriverTourContextProps>(
	{} as DriverTourContextProps
);

// -----

interface DriverTourProps {
	children: ReactNode;
}

export const DriverTour: FC<DriverTourProps> = (props) => {
	const mainTour = useRef<Driver>(null);
	const searchMiniTour = useRef<Driver>(null);
	const { isCompact } = useContext(SizeContext);
	const [showTabs, setShowTabs] = useState(true);

	//function preventDefault(e: { preventDefault: () => void }) {
	//	e.preventDefault();
	//}

	const disableScroll = () => {
		//document.addEventListener("touchmove", preventDefault, { passive: false });
		//document.addEventListener("wheel", preventDefault, { passive: false });
	};

	const enableScroll = () => {
		//document.removeEventListener("touchmove", preventDefault);
		//document.removeEventListener("wheel", preventDefault);
	};

	const startSearchMiniTour = useCallback(async () => {
		const { driver } = await import("driver.js");
		await import("driver.js/dist/driver.css");
		disableScroll();

		searchMiniTour.current = driver({
			popoverClass:
				"bg-background-100! dark:bg-dark-background-300! text-black! dark:text-white!",
			smoothScroll: false,
			showProgress: false,
			allowClose: true,
			nextBtnText: "Weiter",
			prevBtnText: "Zurück",
			doneBtnText: "Los gehts",
			progressText: "Schritt {{current}} von {{total}}",
			onDestroyed: enableScroll,
			steps: [
				{
					element: isCompact ? "#openSearch2" : "#openSearch1",
					popover: {
						title: "Suchfunktion",
						description: "Hier oben kannst du unsere Produkt-Datenbank durchsuchen.",
						side: "bottom" as const,
						align: "center" as const,
						showButtons: []
					}
				}
			]
		});

		searchMiniTour.current.drive();
	}, [isCompact]);

	const startMainTour = useCallback(async () => {
		const { driver } = await import("driver.js");
		await import("driver.js/dist/driver.css");

		if (isCompact) {
			setShowTabs(false);
		}
		disableScroll();

		mainTour.current = driver({
			popoverClass:
				"bg-background-100! dark:bg-dark-background-300! text-black! dark:text-white!",
			smoothScroll: false,
			disableActiveInteraction: true,
			showProgress: true,
			allowClose: false,
			nextBtnText: "Weiter",
			prevBtnText: "Zurück",
			doneBtnText: "Los gehts",
			progressText: "Schritt {{current}} von {{total}}",
			onDestroyed: enableScroll,
			steps: [
				{
					element: isCompact ? "#productHeroTitle" : "#productHero",
					disableActiveInteraction: true,
					popover: {
						title: "❤️-lich Willkommen bei Bestell.bar!",
						description:
							'Im Folgenden möchten wir dir schnell erklären, wie Bestell.bar funktioniert. Nie wieder ein Release verpassen: Wir benachrichtigen dich, sobald dein gewünschtes Produkt (wieder oder erstmalig) bestell.bar ist. Klicke auf "Weiter"!',
						side: "bottom",
						align: "center"
					}
				},
				{
					element: isCompact ? "#p_0" : "#childProducts",
					disableActiveInteraction: true,
					popover: {
						title: "Produktvarianten",
						description:
							'Ein Produkt kann untergeordnete Produktvarianten haben, die beim Klick auf "Alle Alarme aktivieren" ebenfalls abonniert werden. Wenn du stattdessen Alarme nur zu einer bestimmten Variante erhalten möchtest, gelangst du über diese Links hier zur entsprechenden Variante.',
						side: "top",
						align: "center"
					}
				},
				{
					element: "#allAlarms",
					disableActiveInteraction: true,
					popover: {
						title: "Alle Alarme aktivieren",
						description:
							"Abonniert alle Links zu dem ausgewählten Produkt und allen dazugehörigen Produktvarianten.",
						side: "bottom",
						align: "center"
					}
				},
				{
					element: "#followNewLinks",
					disableActiveInteraction: true,
					popover: {
						title: "Automatisch neuen Varianten & Links folgen",
						description:
							'Häkchen setzen, um keine neuen Produktvarianten oder überwachte Händler-Links zu verpassen. Automatisch aktiviert, wenn du "alle Alarme" wählst.',
						side: "bottom",
						align: "center"
					}
				},
				{
					element: "#linkList_0",
					disableActiveInteraction: true,
					popover: {
						title: "Verfügbarkeiten",
						description:
							"Infos zur Verfügbarkeit und ggf. Preis. Klick führt direkt zum Shop. Außerdem kannst du hier Alarme für einzelne Links anlegen.",
						side: "top",
						align: "center"
					}
				},
				{
					element: isCompact ? "updatesTitle" : "#updates",
					disableActiveInteraction: true,
					popover: {
						title: "Updates",
						description:
							"Updates zum ausgewählten Produkt, darunter Infos zur Verfügbarkeit. Zukünftig: Produkt-News und -Hintergrundinfos.",
						side: "top",
						align: "center"
					}
				},
				{
					element: isCompact ? "analysisTitle" : "#analysis",
					disableActiveInteraction: true,
					popover: {
						title: "Analyse",
						description:
							"Wann ist das Produkt endlich wieder verfügbar? Unsere KI erstellt eine Analyse basierend auf vergangenen Verfügbarkeiten.",
						side: "top",
						align: "center"
					}
				},
				{
					element: "#floatingButton",
					disableActiveInteraction: true,
					popover: {
						title: "Los geht's!",
						description:
							"Falls du Probleme hast, klicke einfach auf den Fragezeichen-Button unten rechts. Ansonsten bedenke bitte, dass wir unser Bestes geben, um dich über Verfügbarkeiten zu informieren, aber fehlerhafte Benachrichtigungen unvermeidbar mit dazu gehören. Viel Erfolg! 🍀 dein Bestell.bar Team",
						onNextClick: () => {
							mainTour.current?.destroy();
							setShowTabs(true);
							enableScroll();
							router.visit(route("feed"));
						}
					}
				}
			]
		});

		mainTour.current.drive();
	}, [isCompact]);

	const cachedParams = useMemo(
		() => ({
			startMainTour,
			startSearchMiniTour,
			mainTour,
			searchMiniTour,
			showTabs
		}),
		[startMainTour, startSearchMiniTour, mainTour, searchMiniTour, showTabs]
	);

	return <DriverTourContext value={cachedParams}>{props.children}</DriverTourContext>;
};
