import React, { createContext, FC, ReactNode, useEffect, useState } from "react";

const isSSR = import.meta.env.SSR;

export type SizeContextProps = {
	isCompact: boolean;
	isMedium: boolean;
	isLarge: boolean;
};

export const SizeContext = createContext<SizeContextProps>({
	isCompact: isSSR ?? false,
	isMedium: false,
	isLarge: false
});

// -----

interface SizeProviderProps {
	children: ReactNode;
}

export const SizeProvider: FC<SizeProviderProps> = ({ children }) => {
	const [isCompact, setIsCompact] = useState<boolean>(isSSR ?? false);
	const [isMedium, setIsMedium] = useState<boolean>(false);
	const [isLarge, setIsLarge] = useState<boolean>(false);

	useEffect(() => {
		const updateToggle = () => {
			setIsCompact(window.innerWidth < 640);
			setIsMedium(window.innerWidth < 768);
			setIsLarge(window.innerWidth < 1024);
		};

		if (typeof window !== "undefined") {
			updateToggle();
			window.addEventListener("resize", updateToggle);
			return () => window.removeEventListener("resize", updateToggle);
		}
	}, []);

	return <SizeContext value={{ isCompact, isMedium, isLarge }}>{children}</SizeContext>;
};
