import React, { createContext, ReactNode, useEffect, useState } from "react";

export type LocationType = Pick<Location, "pathname" | "search" | "hash">;

export type LocationContextProps = {
	location?: LocationType | null;
};

export const LocationContext = createContext<LocationContextProps>({
	location: null
});

// -----

interface LocationProviderProps {
	children: ReactNode;
}

export const LocationProvider: React.FC<LocationProviderProps> = ({ children }) => {
	const [location, setLocation] = useState<LocationType | null>(null);

	if (typeof window !== "undefined") {
		useEffect(() => {
			//NOSONAR
			setLocation(window.location);
		}, [window.location]);
	}

	return <LocationContext value={{ location: location }}>{children}</LocationContext>;
};
