import { ReactNode } from "react";

import { DriverTour } from "@/ContextProviders/DriverTour";
import { FirebaseProvider } from "@/ContextProviders/FirebaseProvider";
import { LocationProvider } from "@/ContextProviders/LocationProvider";
import { ModalProvider } from "@/ContextProviders/ModalProvider";
import { SearchProvider } from "@/ContextProviders/SearchProvider";
import { SizeProvider } from "@/ContextProviders/SizeProvider";
import { StorageProvider } from "@/ContextProviders/StorageProvider";

interface AppDependencyProps {
	children: ReactNode;
}

export const AppDependencies: React.FC<AppDependencyProps> = ({ children }) => {
	return (
		<LocationProvider>
			<ModalProvider>
				<FirebaseProvider>
					<SizeProvider>
						<SearchProvider>
							<DriverTour>
								<StorageProvider>{children}</StorageProvider>
							</DriverTour>
						</SearchProvider>
					</SizeProvider>
				</FirebaseProvider>
			</ModalProvider>
		</LocationProvider>
	);
};
