import "./bootstrap";
import "./../css/app.css";
import "./sentry";
import "nprogress/nprogress.css";

import { createInertiaApp, router } from "@inertiajs/react";
import * as Sentry from "@sentry/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import NProgress from "nprogress";
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { startRippleEffect } from "tailwind-plugin-ripple-effect/start.js";

import { AppDependencies } from "./ContextProviders/AppProvider";

const isSSR = import.meta.env.SSR ?? false;
let progressBarTimeout: ReturnType<typeof setTimeout> | null = null;

NProgress.configure({ showSpinner: false });

const clearProgressBarTimeout = () => {
	if (progressBarTimeout) {
		clearTimeout(progressBarTimeout);
		progressBarTimeout = null;
	}
};

startRippleEffect();

router.on("start", () => {
	clearProgressBarTimeout();
	progressBarTimeout = setTimeout(() => {
		NProgress.start();
	}, 250);
});

router.on("finish", () => {
	clearProgressBarTimeout();
	NProgress.done();
});

/*
if (typeof window !== 'undefined') {
	window.addEventListener("popstate", (event) => {
		event.stopImmediatePropagation();

		router.visit(window.location.href, {
			preserveState: false,
			preserveScroll: false,
			replace: true,
		});
	});
}*/

createInertiaApp({
	progress: false,
	title: (title) => title,
	resolve: (name) =>
		resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob("./Pages/**/*.tsx")),
	setup({ el, App, props }) {
		const SentryWrappedApp = Sentry.withProfiler((appProps) => (
			<React.StrictMode>
				<AppDependencies>
					<App {...props} {...appProps} />
				</AppDependencies>
			</React.StrictMode>
		));

		if (isSSR) {
			hydrateRoot(el, <SentryWrappedApp />);
		} else {
			createRoot(el).render(<SentryWrappedApp />);
		}
	}
}).then(() => {
	// clean up devtools
	document.getElementById("app")?.removeAttribute("data-page");
});
