import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from "react";

export type ModalContextProps = {
	// WIP: availability modal
	setShowAvailabilityModal: Dispatch<SetStateAction<boolean>>;
	showAvailabilityModal: boolean;

	// waiting for notification permission response
	setShowPopupWaitingModal: Dispatch<SetStateAction<boolean>>;
	showPopupWaitingModal: boolean;

	// permission denied
	setShowPopupBlockedModal: Dispatch<SetStateAction<boolean>>;
	showPopupBlockedModal: boolean;

	// blocked open new tab
	setShowPopupTabsModal: Dispatch<SetStateAction<boolean>>;
	showPopupTabsModal: boolean;

	// are you sure you want to remove the account
	setShowRemoveAccountModal: Dispatch<SetStateAction<boolean>>;
	showRemoveAccountModal: boolean;

	openInNewTab: (url: string) => boolean;
};

export const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

// -----

interface ModalProviderProps {
	children: ReactNode;
}

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
	const [showAvailabilityModal, setShowAvailabilityModal] = useState<boolean>(false);
	const [showPopupWaitingModal, setShowPopupWaitingModal] = useState<boolean>(false);
	const [showPopupBlockedModal, setShowPopupBlockedModal] = useState<boolean>(false);
	const [showPopupTabsModal, setShowPopupTabsModal] = useState<boolean>(false);
	const [showRemoveAccountModal, setShowRemoveAccountModal] = useState<boolean>(false);

	const openInNewTab = (url: string): boolean => {
		if (typeof window === "undefined") return false;
		const success = window.open(url, "_blank");

		if (success) {
			success.focus();
		} else {
			console.error("[🖥️] popup was blocked by browser");
			setShowPopupTabsModal(true);
		}

		return success !== null;
	};

	return (
		<ModalContext
			value={{
				showAvailabilityModal,
				setShowAvailabilityModal,
				showPopupWaitingModal,
				setShowPopupWaitingModal,
				showPopupBlockedModal,
				setShowPopupBlockedModal,
				showPopupTabsModal,
				setShowPopupTabsModal,
				showRemoveAccountModal,
				setShowRemoveAccountModal,
				openInNewTab
			}}>
			{children}
		</ModalContext>
	);
};
