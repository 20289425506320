import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

import { BB_Link } from "@/Components/BB_Footer";
import BB_Product from "@/types/BB_Product";

const SEARCH_HISTORY_KEY = "searchHistory";

export type SearchItem = BB_Product | BB_Link;

const getStoredSearchHistory = (): SearchItem[] => {
	try {
		return JSON.parse(localStorage.getItem(SEARCH_HISTORY_KEY) ?? "[]");
	} catch {
		localStorage.removeItem(SEARCH_HISTORY_KEY);
		return [];
	}
};

const storeSearchTerm = (term: SearchItem) => {
	let history = getStoredSearchHistory();

	// remove old duplicates to bring the term to front
	history = history.filter((item: SearchItem) => item.id !== term.id);

	if (history.length > 4) {
		// Remove the oldest item to make space for the new one
		history.pop();
	}

	// add new term to front
	history.unshift(term);
	localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(history));
};

export type SearchContextType = {
	searchHistory: SearchItem[];
	addSearchTerm: (term: SearchItem) => void;
	clearHistory: () => void;
};

export const SearchContext = createContext<SearchContextType>({
	searchHistory: [],
	addSearchTerm: () => {},
	clearHistory: () => {}
});

// ------

export type SearchProviderProps = {
	children: ReactNode;
};

export const SearchProvider = (props: SearchProviderProps) => {
	const [searchHistory, setSearchHistory] = useState<SearchItem[]>([]);

	useEffect(() => {
		setSearchHistory(getStoredSearchHistory());
	}, []);

	const addSearchTerm = (term: SearchItem) => {
		storeSearchTerm(term);
		setSearchHistory(getStoredSearchHistory());
	};

	const clearHistory = () => {
		localStorage.clear();
	};

	const cachedParams = useMemo(
		() => ({
			searchHistory,
			addSearchTerm,
			clearHistory
		}),
		[searchHistory, addSearchTerm, clearHistory]
	);

	return <SearchContext.Provider value={cachedParams}>{props.children}</SearchContext.Provider>;
};
