import React, {
	createContext,
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useEffect,
	useState
} from "react";

export type StorageContextProps = {
	setFancyAnimations: Dispatch<SetStateAction<boolean>>;
	fancyAnimations: boolean;
};

export const StorageContext = createContext<StorageContextProps>({} as StorageContextProps);

// -----

interface StorageProviderProps {
	children: ReactNode;
}

export const StorageProvider: FC<StorageProviderProps> = ({ children }) => {
	const [fancyAnimations, setFancyAnimations] = useState<boolean>(false);
	useEffect(() => {
		if (typeof localStorage !== "undefined") {
			setFancyAnimations(localStorage.getItem("fancyAnimations") === "true");
		}
	}, []);

	useEffect(() => {
		if (typeof localStorage !== "undefined") {
			localStorage.setItem("fancyAnimations", JSON.stringify(fancyAnimations));
		}
	}, [fancyAnimations]);

	return (
		<StorageContext
			value={{
				fancyAnimations,
				setFancyAnimations
			}}>
			{children}
		</StorageContext>
	);
};
