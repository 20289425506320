try {
	// hide annoying react devtools warning
	if (typeof window !== "undefined") {
		window.__REACT_DEVTOOLS_GLOBAL_HOOK__ = { isDisabled: true };
	}
} catch {
	/* empty */
}

// hide logs in production
if (import.meta.env.VITE_APP_ENV === "production") {
	console.log = () => {};
	console.warn = () => {};
	console.error = () => {};
	console.info = () => {};
	console.debug = () => {};
	console.trace = () => {};
}
